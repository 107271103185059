<template>
	<div style="height: 100vh; display: flex; flex-direction: column; position: relative;">
    <div v-if="pageInfo.target_type == 2">
      <div @click="openMini">
        <img :src="pageInfo.mini_banner" style="width: 100%; object-fit: contain;">
      </div>
      <div @click="openUrl">
        <img :src="pageInfo.act_banner" style="width: 100%; object-fit: contain;">
      </div>
    </div>
		<!-- <div style="flex: 1;">
			<iframe style="width: 100%; height: 100%;" :src="adv_url" frameborder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe>
		</div>
		<div style="display: flex; height: 50px; color: white; font-size: 15px;">
			<div style="flex: 1; height: 50px;">
				<div style="background: #f0516e; height: 50px; display: flex; align-items: center; justify-content: center;">抽奖活动</div>
			</div>
			<div style="flex: 1; height: 50px;">
        <wx-open-launch-weapp style="width: 100%;" v-if="isWeixin" id="launch-btn" :username="appId" :path="jumpPath">
          <template>
            <button style="width: 200px; background: #0e7ae5; height: 50px; display: flex; align-items: center; justify-content: center;">换购活动</button>
          </template>
        </wx-open-launch-weapp>
        <div v-else @click="click2" style="background: #0e7ae5; height: 50px; display: flex; align-items: center; justify-content: center;">换购活动</div>
			</div>
		</div>
 -->
    <div v-if="loading" style="position: absolute; left:0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
      <div style="width: 150px; height: 120px; border-radius: 10px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1); display: flex; align-items: center; justify-content: center; background: white; overflow: hidden;">
          <img src="https://img.zcool.cn/community/014f6d5b930faaa8012017eea7c831.gif" alt="" width="150">
      </div>
    </div>
	</div>
</template>

<script>
  import $ from "jquery";
  // var appId = "wxfff7e37f06075d02";
  // var resourceEnv = "cloud1-7gyj966r0b0700e5"

  var appId = "wxc3d645c4b501ae21";
  var resourceEnv = "cloud1-7g8nm9dm8d1a6c91"

  // var appId = "wxf83736d88e356bf4";
  // var resourceEnv = "p-1-8g6s9dnsc703048e"
	import { mapGetters, mapActions } from 'vuex'
	export default {
		data() {
			return {
        appId,
        jumpPath: "",
        isWeixin: false,
        loading: false
			}
		},
    computed: {
      ...mapGetters([
        'pageInfo'
      ])
    },
    mounted() {
      // let scripts = [`https://res.wx.qq.com/open/js/jweixin-1.6.0.js`, "https://res.wx.qq.com/open/js/cloudbase/1.1.0/cloud.js"];
      // for (let src of scripts) {
      //   let script= document.createElement('script');
      //   script.type = 'text/javascript';
      //   script.src = src;
      //   document.body.appendChild(script);
      // }
    },
		created() {
      this.loading = true;
      this.load_progess = 0;
      document.title = ""
      let url = this.$route.params.url;
      this.url = url;
      if (url != null) {
        this.jumpPath = "pages/client/client?url="+url;
      } else {
        this.jumpPath = "pages/client/client";
      }
      this.checkCode({code: url, cb: ()=>{
        this.loaded();
      }});

      this.docReady(async ()=> {

        var ua = navigator.userAgent.toLowerCase()
        var isWXWork = ua.match(/wxwork/i) == 'wxwork'
        var isWeixin = !isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
        var isMobile = false
        var isDesktop = false
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
          isMobile = true
        } else {
          isDesktop = true
        }
        // this.isWeixin = isWeixin
        if (isWeixin && false) {
          this.initMini();
        } else if (isDesktop) {
         
        }  else {
          console.log("weidenglu")
          var c = new cloud.Cloud({
            // 必填，表示是未登录模式
            identityless: true,
            // 资源方 AppID
            resourceAppid: appId, // <!-- replace -->
            // 资源方环境 ID
            resourceEnv: resourceEnv, // <!-- replace -->
          })
          await c.init()
          window.c = c
          this.loaded();
        }
      })
		},
		methods: {
      ...mapActions([
        'checkCode'
      ]),
      loaded() {
        this.load_progess++;
        if (this.load_progess >= 2) {
          this.loading = false;
          if (this.pageInfo.target_type == 3) {
            this.openWeapp();
          }
        }
      },
      initMini() {
        alert(document.location.href)
        var vo = {
          // url: "http://127.0.0.1:9021/jsjz-api/api/get_landweb_signature",
          url: "https://ma.genyun2019.cn/jsjz-api/api/get_landweb_signature",
          type: "POST",
          // dataType: "json",
          data: "url="+document.location.href+"&appId="+appId,
          success: function(res){
            console.log(res)
            if (res.err == 0) {
              wx.config({
                debug: true, // 调试时可开启
                appId: appId, // <!-- replace -->
                timestamp: res.data.timestamp, // 必填，填任意数字即可
                nonceStr: res.data.nonce, // 必填，填任意非空字符串即可
                signature: res.data.signature, // 必填，填任意非空字符串即可
                jsApiList: ['chooseImage'], // 必填，随意一个接口即可 
                openTagList:['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
              })
            }
          }
        }
        $.ajax (vo);
        
      },
      docReady(fn) {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
          setTimeout(() => {
            fn()
          }, 1000);
        } else {
          setTimeout(() => {
            document.addEventListener('DOMContentLoaded', fn);
          }, 1000);
        }
      },
			openUrl() {
        if (this.pageInfo.advUrl != null) {
          window.location.href = this.pageInfo.advUrl;
        }
			},
      openMini() {
        this.openWeapp();
      },
      async openWeapp(onBeforeJump) {
        var c = window.c
        this.loading = true;
        
        try {
          const res = await c.callFunction({
            name: 'getUrlScheme',//public
            data: {
              action: 'getUrlScheme',
              url: this.url
            }
          })
          this.loading = false;
          // console.warn(res)
          if (onBeforeJump) {
            onBeforeJump()
          }
          location.href = res.result.openlink
        } catch (error) {
          this.loading = false;
        }
      }
		}
	}
</script>

<style>
body {
  margin: 0;
}
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.logo {
		height: 100px;
		width: 100px;
		margin-top: 100px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;
	}

	.text-area {
		display: flex;
		justify-content: center;
	}

	.title {
		font-size: 18px;
		color: #8f8f94;
	}
</style>
