import { createStore } from 'vuex'
import $ from "jquery"

// var host = "http://127.0.0.1:9021/jsjz-api/"
var host = "https://ma.genyun2019.cn/jsjz-api/"

export default createStore({
  state: {
    pageInfo: {}
  },
  getters: {
    pageInfo(state) {
      return state.pageInfo;
    }
  },
  mutations: {
    pageInfo(state, v) {
      state.pageInfo = v;
    }
  },
  actions: {
    checkCode({commit}, {code, cb}) {
      var vo = {
        url: host+"api/read_qrcode",
        type: "POST",
        dataType: "json",
        // contentType: "application/json",
        data: `url=${code}`,
        success: function(res){
          // console.log(res)
          if (res.err == 0) {
            commit("pageInfo", res.data);
            if (res.data.target_type == 1) {
              window.location.href = res.data.advUrl;
              return;
            }
            if (cb) {
              cb();
            }
            // let valid = res.valid;
            // commit("adv_url", res.data.advUrl);
            // commit("act_url", res.data.actUrl);
          }
        }
      }
      $.ajax (vo);
    }
  },
  modules: {
  }
})
